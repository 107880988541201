/* ===== General Layout ===== */
.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 40px;
  max-width: 100%; /* Ensures content doesn’t overflow */
  min-height: 100vh; /* Makes sure it takes the full height */
  margin-top: -3.5vw;
}

/* ===== Headings ===== */
.homepage-container h1 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
}

.homepage-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.homepage-container h3 {
  font-size: 22px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 10px;
}

.homepage-container p {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto 20px auto;
  color: rgb(255, 255, 255);
  text-align: center;
}

/* ===== Hero Section - Overall Layout (Full Background Video) ===== */
.hero-section {
  position: relative;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
  width: 100%;
  padding: 20px;
}

/* Removed the split - Full Background Video */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4; /* Adjust for better text visibility */
  z-index: 1;
}

/* Text Overlay on Video - Centered */
.hero-text {
  position: relative;
  z-index: 2; /* Ensure text appears above the video */
  font-weight: bold;
  color: white;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-top: -18vh;
}

/* Headings - Centered */
.hero-text h3 {
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
}

/* Pyramid-style text alignment */
.line1, .line2, .line3 {
  font-size: 3rem;
  text-align: center;
  display: block;
  margin: auto;
}

/* Button Section */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Mobile Adjustments */
@media (max-width: 600px) {
  .hero-text h3 {
    font-size: 28px; /* Adjust font size for smaller screens */
  }

  .line1, .line2, .line3 {
    font-size: 1.8rem;
    padding-left: 0 !important; /* Center everything on smaller screens */
  }
}


/* Right Side - Content */
.hero-right {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 600px;
  color: white;
  padding: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .hero-right {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .hero-right {
    text-align: center;
  }
}

/* Paragraph */
.hero-right p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Button Section */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.hero-button {
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: 0.3s ease;
}

.hero-button:hover {
  background: #ffffffd9;
  color: #0056b3;
  transform: scale(1.05);
}


/* CTA Button */
.cta-button {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  padding: 14px 28px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  background: white;
  color: #0056b3;
  transform: scale(1.07);
}

.content-section{
  max-width: 1000px;
  margin: 10px auto;
  padding: 20px;
  text-align: center;
  margin-top: 50px;
}


/* ===== Features Section ===== */
.features-section {
  max-width: 1000px;
  margin: 10px auto;
  padding: 20px;
  text-align: center;
  margin-top: -10px;
  /* background-color: transparent !important */
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* More responsive */
  gap: 30px;
  margin-top: 20px;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

/* Tablets (2 columns) */
@media (max-width: 1023px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
  }
}

/* Mobile Phones (1 column) */
@media (max-width: 599px) {
  .features-grid {
    grid-template-columns: 1fr; /* 1 column */
  }
}

/* Feature Card Styling */
.feature-card {
  background: rgba(255, 255, 255, 0.2); /* Glass effect */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(8px); /* Smooth blending */
}

.feature-card img {
  width: 110px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.feature-card:hover img {
  transform: scale(1.1);
}

.feature-card:hover {
  transform: translateY(-5px);
  /* transform: scale(1.05); */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

/* ===== Staggered List Section ===== */
.topics-section {
  background-color: transparent !important;
  padding: 10px;
  position: relative;
  z-index: 2; /* Ensure it's above any overlapping backgrounds */
}

/* ===== Testimonials Section ===== */
.testimonials-section {
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
  padding: 40px 20px;
  background: transparent;
  border-radius: 10px;
}

.testimonial {
  background: transparent;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-style: italic;
}

.testimonial img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.testimonial span {
  display: block;
  font-weight: bold;
  margin-top: 10px;
  color: #007bff;
}

/* ===== Call-to-Action Section ===== */
.cta-section {
  text-align: center;
  background: transparent;
  color: rgb(0, 0, 0);
  padding: 40px 20px;
  border-radius: 10px;
  margin: 40px 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}

.cta-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.cta-section p {
  font-size: 16px;
  color: #ffffff;
  max-width: 600px;
  margin: 0 auto 20px auto;
}

/* Reduce gap between sections */
.features-section, .topics-section, .testimonials-section, .cta-section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: transparent !important;
  backdrop-filter: blur(5px); /* Subtle glass effect */
}
