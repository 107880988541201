/* ===== Article Page Layout ===== */
.article-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 0px;
    background-color: transparent;
    border-radius: 0px;
    line-height: 1.8;
    margin-top: -18vh;
}

.article-container h1 {
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center; /* Center the text */
    margin-bottom: 20px;
    padding-bottom: 10px; /* Space between text and underline */
    word-wrap: break-word; /* Ensures long text wraps properly */
    overflow-wrap: break-word; /* Prevents text overflow */
    max-width: 100%; /* Prevents super long titles from extending out of the container */
    margin-left: auto;
    margin-right: auto;
}

/* Dynamic Underline for h1 */
.article-container h1::after {
    content: "";
    display: block;
    width: 100%; /* Limits underline width to 50% of the title */
    height: 4px;
    background-color: #65adfa;
    margin: 10px auto 0 auto; /* Centers the underline */
    border-radius: 2px;
}


/* Subheadings */
.article-container h2 {
    font-size: 28px;
    font-weight: bold;
    color: white;
    margin-top: 40px;
    border-bottom: 2px solid #ddd;
    padding-bottom: 8px;
    text-align: center;
}

.article-container h3 {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin-top: 25px;
    text-align: center;
}

/* ===== Paragraphs ===== */
.article-container p {
    font-size: 17px;
    color: white;
    text-align: justify;
    margin-bottom: 18px;
    line-height: 1.8;
}

/* ===== Highlighted Text (For Important Notes) ===== */
.article-container strong {
    color: #ffffff;
}

/* ===== Blockquote for Emphasized Content ===== */
.article-container blockquote {
    font-size: 18px;
    font-style: italic;
    color: #ffffff;
    padding: 15px;
    border-left: 4px solid #007bff;
    background: #f9f9f9;
    margin: 20px 0;
}

/* ===== List Styling ===== */
.article-container ul, ol {
    padding-left: 25px;
    margin-bottom: 20px;
}

.article-container li {
    font-size: 16px;
    color: #ffffff;
    padding: 6px 0;
}

/* ===== Hyperlinks ===== */
.article-container a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.article-container a:hover {
    color: #0056b3;
    text-decoration: underline;
}

/* ===== Call-to-Action Button ===== */
.article-button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #007bff, #0056b3);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    margin-top: 20px;
}

.article-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: scale(1.05);
}

/* ===== Article Sections (For Better Readability) ===== */
.article-section {
    margin-top: 40px;
    padding: 25px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* ===== Responsive Design ===== */
@media (max-width: 768px) {
    .article-container {
        max-width: 90%;
        padding: 20px;
    }

    .article-container h1 {
        font-size: 28px;
    }

    .article-container h2 {
        font-size: 24px;
    }

    .article-container p {
        font-size: 16px;
    }

    .article-button {
        font-size: 14px;
        padding: 10px 20px;
    }
}
