/* ===== Page Layout ===== */
.cap-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2vw; /* Adjusted to a relative value */
    margin-top: 0; /* Prevents extra space at the top */
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: clamp(1vw, 2vw, 3vw); /* Adjusted gap to scale with the screen */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* ===== Centered Page Title ===== */
.cap-container h1 {
    font-size: 36px; /* Font size relative to viewport */
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    margin-top: 0.5rem; /* Adjusted to relative value */
    padding-bottom: 1px;
}

/* Dynamic Underline */
.cap-container h1::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
}

.cap-container strong {
    color: #ffffff;
}

.cap-container li {
    color: #ffffff;
}

/* ===== Section Layout (Text + Image Side by Side) ===== */
.cap-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2vw; /* Adjusted gap to scale with the screen */
    border-radius: 12px;
    padding: 2vw;
    transition: transform 0.3s ease-in-out;
    margin-top: 0;
}

/* Alternate layout for every other section */
.cap-section:nth-child(even) {
    flex-direction: row-reverse;
}

/* ===== Text Content Section ===== */
.text-content {
    flex: 1;
    max-width: 600px;
    text-align: left;
}

/* ===== Image Section ===== */
.image-container {
    flex: 1;
    max-width: 500px;
    margin-left: 10px;
}

.image-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

/* Hover effect for images */
.image-container img:hover {
    transform: scale(1.05);
}

/* ===== Subheadings (h2, h3) ===== */
.cap-container h2 {
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

.cap-container h3 {
    font-size: 22px; /* Relative to viewport */
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

/* ===== Paragraphs ===== */
.cap-container p {
    font-size: 16px; 
    line-height: 1.8;
    color: #ffffff;
    text-align: justify;
}


/* ===== Buttons ===== */
.cap-button {
    display: inline-block;
    padding: .7vw .7vw; /* Adjusted padding to scale */
    font-size: 1vw; /* Font size relative to viewport */
    font-weight: bold;
    color: white;
    background: linear-gradient(135deg, #007bff, #0056b3);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    margin-top: 4vh;
}

.cap-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: scale(1.05);
}

/* ===== Responsive Design (Stack on smaller screens) ===== */
@media (min-width: 1400px) {
    .cap-container {
        margin-top: -10vh; /* Adjust space on very large screens */
        max-width: 1200px;
    }
}

@media (max-width: 768px) {
    .cap-container {
        margin-top: 2vh; /* Less space at the top */
        padding: 2vw; /* Adjust padding */
    }

    .cap-section {
        flex-direction: column; /* Stack vertically on mobile */
        text-align: center;
    }

    .image-container img {
        width: 90%;
        height: auto;
    }

    .cap-section:nth-child(even) {
        flex-direction: column;
    }
}

@media (max-width: 1024px) {
    .cap-section {
        flex-direction: column;
        text-align: center;
        gap: 2vw;
    }

    .text-content, .image-container {
        max-width: 100%;
    }

    .image-container img {
        width: 90%;
    }

    .cap-container h1 {
        font-size: 6vw;
    }

    .cap-container h2 {
        font-size: 5vw;
    }

    .cap-container p {
        font-size: 4vw;
    }

    .cap-button {
        font-size: 3vw;
        padding: 3vw 5vw;
    }
}
